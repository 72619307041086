<template>
  <b-card>
    <!-- body -->
    <b-card-body>
      <b-tabs>
        <b-tab
            active
            title="讨论">
          <div
              v-for="discussion in discussions">
            <b-card
                no-body
                v-on:click="jump(discussion.link)"
                style="cursor:pointer">
              <b-card-header class="pb-1">
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar
                        size="42"
                        :src="discussion.user_avatar"/>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">
                      {{ discussion.user_name }}
                    </h5>
                    <small class="text-muted">{{ discussion.release_time }}</small>
                  </b-media-body>
                </b-media>
                <b-media-aside
                    class="mr-1"
                    right>
                  <b-badge
                      :variant="discussion.color"
                      class="badge-glow">
                    {{ discussion.result }}
                  </b-badge>
                </b-media-aside>
              </b-card-header>
              <b-card-body>
                <h4 class="apply-job-title">
                  {{ discussion.title }}
                </h4>
                <b-card-text class="mb-2" v-html="discussion.content">
                  {{ discussion.content }}
                </b-card-text>
              </b-card-body>
              <hr>
            </b-card>
          </div>
          <b-button
              size="sm"
              variant="outline-primary"
              v-on:click="getDiscussions">
            显示更多
          </b-button>
        </b-tab>
        <b-tab
            title="分析">
          <stock-analyze-card :stock-code="stockCode">

          </stock-analyze-card>
        </b-tab>
      </b-tabs>
    </b-card-body>
  </b-card>
</template>
<script>

import {
  BCard,
  BCardHeader,
  BCardBody,
  BTabs,
  BTab,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BAvatar,
  BButton,
  BBadge,
} from 'bootstrap-vue'
import StockAnalyzeCard from "@/views/Stock/StockAnalyzeCard";


export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BTabs,
    BTab,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BButton,
    BBadge,
    StockAnalyzeCard,
  },
  props: {
    stockCode: {
      required: true,
    },
  },
  data() {
    return {
      discussions: [],
      page: 1,
    }
  },
  created() {
    this.getDiscussions()
  },
  methods: {
    getDiscussions() {
      this.$http.get('/api/data/get_stock_discussions/' + this.stockCode + "?page=" + this.page)
          .then(
              response => {
                let res = response.data.data
                let sentences = []
                res.items.forEach(element => {
                  element.link = "https://xueqiu.com/" + element.user_id + "/" + element.discussion_id
                  element.result = ""
                  element.color = ""
                  sentences.push(element.content)
                })
                let startIndex = this.discussions.length
                this.discussions = this.discussions.concat(res.items)
                this.page++
                this.getEmotion(startIndex, sentences)
              }).catch()
    },
    getEmotion(startIndex, items) {
      this.$http.post("/api/model/emotion", {
        "sentences": items
      }, {
        headers: {"Content-Type": "application/json; charset=UTF-8"}
      }).then(response => {
        response.data.data.forEach(element => {
          this.discussions[startIndex].result = element.result
          console.log(this.discussions[startIndex].result)

          if (this.discussions[startIndex].result === "正向")
            this.discussions[startIndex].color = "success"
          else
            this.discussions[startIndex].color = "danger"
          startIndex++
        })
        console.log(this.discussions)
      }).catch(error => {

      })
    },
    jump(link) {
      window.open(link)
    },
  }
}
</script>

<style scoped>

</style>