<template>
  <b-card>
    <b-card-title>
      公司简介
    </b-card-title>
    <b-card-text>
      {{ info.org_cn_introduction }}
    </b-card-text>
    <hr>
    <b-card-title>
      其他信息
    </b-card-title>
    <b-card-text>
      业务：{{ info.main_operation_business }}
    </b-card-text>
    <b-card-text>
      公司网址：{{ info.org_website }}
    </b-card-text>
    <b-card-text>
      办公地址：{{ info.office_address_cn }}
    </b-card-text>
    <b-card-text>
      公司电话：{{ info.telephone }}
    </b-card-text>
    <b-card-text>
      电子邮箱：{{ info.email }}
    </b-card-text>
    <b-card-text>
      实际控制人：{{ info.actual_controller }}
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardText,
  BCardTitle,
  BLink,
  BCardBody,
  BCardSubTitle,
  BCardHeader,
  BButton,
} from "bootstrap-vue";
import stockInfo from "@/views/Stock/StockInfo";
import Toast from "@core/components/toastification/ToastificationContent";

export default {
  components: {
    BCard,
    BCardTitle,
    BCardText,
    BLink,
    BCardBody,
    BCardSubTitle,
    BCardHeader,
    BButton,
  },
  props: {
    stockCode: {
      required: true,
    },
  },
  created() {
    this.$http.get("/api/data/get_stock_info/" + this.stockCode).then(response => {
      if (response.data.code !== 200) {
        this.showToast("danger", "抱歉", "AlertCircleIcon", response.data.data, "top-center", 2000)
        this.$router.push({name: "error-404"})
      } else {
        this.info = response.data.data
        for (let i in this.info) {
          if (this.info[i] === ''||this.info[i] === null)
            this.info[i] = "无数据"
        }
      }
    }).catch(error => {
      this.showToast("danger", "抱歉", "AlertCircleIcon", "服务器错误：" + error, "top-center", 2000)
    })

  },
  data() {
    return {
      info: {
        org_cn_introduction: "",
        main_operation_business: "",
        org_website: "",
        office_address_cn: "",
        telephone: "",
        email: "",
        actual_controller: "",
      }
    }
  },
  methods: {
    showToast(variant, title, icon, text, position, timeout) {
      this.$toast({
            component: Toast,
            props: {
              title: title,
              icon: icon,
              text: text,
              variant,
            },
          },
          {
            position,
            timeout
          })
    }
  }
}
</script>

<style scoped>

</style>