<template>
  <b-card>
    <b-card-body v-show="isLogged&&isSelected">
      <b-row>
        <b-col lg="12">
          <b-card>
            <b-card-header>
              <!-- title and subtitle -->
              <div>
                <b-card-title class="mb-1">
                  好评率
                </b-card-title>
                <b-card-sub-title>正负向评论比</b-card-sub-title>
              </div>
              <!--/ title and subtitle -->

            </b-card-header>

            <b-card-body>
              <vue-apex-charts
                  ref="lineChart"
                  type="line"
                  height="400"
                  :options="lineChart.lineChartOptions"
                  :series="lineChart.lineChartSeries"/>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col lg="6">
          <b-card>
            <b-card-title class="mb-1">
              评价比例
            </b-card-title>
            <b-card-sub-title class="mb-2">
              仅情感向量
            </b-card-sub-title>
            <vue-apex-charts
                type="donut"
                height="350"
                :options="donutChart.chartOptions"
                :series="donutChart.series"/>



          </b-card>
        </b-col>
        <b-col lg="6">
          <b-card>
            <b-card-title class="mb-1">
              购入评级
            </b-card-title>
            <b-card-sub-title class="mb-2">
              仅作为交易意见，请勿以该指标为准
            </b-card-sub-title>
            <vue-apex-charts
                type="radialBar"
                height="400"
                :options="radialBarChart.chartOptions"
                :series="radialBarChart.series"/>

          </b-card>
        </b-col>
      </b-row>
    </b-card-body>
    <b-card-body v-show="showNoLog" class="text-center"><h3>未登录</h3></b-card-body>
    <b-card-body v-show="showNotSelected" class="text-center"><h3>您需要先添加为自选股</h3></b-card-body>
    <b-card-body v-show="showNoData" class="text-center"><h3>暂无数据，请过至少24小时再进行访问</h3></b-card-body>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BTabs,
  BTab,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BAvatar,
  BButton,
  BBadge,
  BCardSubTitle,
  BCardTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import {$themeColors} from "@themeConfig";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BTabs,
    BTab,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BButton,
    BCardSubTitle,
    BBadge,
    BCardTitle,
    VueApexCharts,
  },
  created() {
    this.$http.get('/api/system/stock/userSelect/analysis/' + this.stockCode)
        .then(
            response => {
              if (response.data.code === 401001) {
                this.showNoLog = true
                return
              }
              if (response.data.code === 403004) {
                this.showNotSelected = true
                return
              }
              if (response.data.code !== 200) {
                return
              }
              let data = response.data.data.ranks
              let buy_rank = response.data.data.buy_rank
              if (data.length === 0) {
                this.showNoData = true
                return
              }
              let lineChartX = []
              let lineChartY = []
              data.forEach(e => {
                lineChartX.push(e.day)
                lineChartY.push(parseInt(e.positive_rank * 100))
              })
              this.isLogged = true
              this.isSelected = true
              this.lineChart.lineChartSeries = [{data: lineChartY.reverse()}]
              this.lineChart.lineChartOptions.xaxis.categories = lineChartX.reverse()
              this.$refs.lineChart.updateSeries(this.lineChart.lineChartSeries)
              this.$refs.lineChart.updateOptions(this.lineChart.lineChartOptions)
              this.donutChart.series=[lineChartY[lineChartY.length-1],100-lineChartY[lineChartY.length-1]]
              this.radialBarChart.series=[parseInt(buy_rank*100),lineChartY[lineChartY.length-1],100-lineChartY[lineChartY.length-1]]


            }).catch()
  },
  data() {
    return {
      isLogged: false,
      isSelected: false,
      showNoLog: false,
      showNotSelected: false,
      showNoData: false,
      lineChart: {
        lineChartOptions: {
          chart: {
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },
          markers: {
            strokeWidth: 7,
            strokeOpacity: 1,
            strokeColors: [$themeColors.light],
            colors: [$themeColors.warning],
          },
          colors: [$themeColors.warning],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          tooltip: {
            custom(data) {
              return `${'<div class="px-1 py-50"><span>'}${
                  data.series[data.seriesIndex][data.dataPointIndex]
              }%</span></div>`
            },
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            max: 100,
            min: 0,
            tickAmount: 5,
            title: {
              text: "好评率（%）",
            }
          },
        },
        lineChartSeries: [],
      },
      donutChart: {
        series: [50, 50],
        chartOptions: {
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '14px',
            fontFamily: 'Montserrat',
          },
          colors: [
            '#FFA1A1',
            '#00d4bd',
          ],
          dataLabels: {
            enabled: true,
            formatter(val) {
              return `${parseInt(val)}%`
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '2rem',
                    fontFamily: 'Montserrat',
                  },
                  value: {
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    formatter(val) {
                      return `${parseInt(val)}%`
                    },
                  },
                  total: {
                    show: false,
                  },
                },
              },
            },
          },
          labels: ['正向', '负向'],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '1.5rem',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1.5rem',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },
      radialBarChart: {
        series: [0, 0, 0],
        chartOptions: {
          colors: ['#826bf8', '#FFA1A1', '#00d4bd'],
          plotOptions: {
            radialBar: {
              size: 185,
              hollow: {
                size: '25%',
              },
              track: {
                margin: 15,
              },
              dataLabels: {
                name: {
                  fontSize: '2rem',
                  fontFamily: 'Montserrat',
                },
                value: {
                  fontSize: '1rem',
                  fontFamily: 'Montserrat',
                },
                total: {
                  show: false,
                },
              },
            },
          },
          legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
          },
          stroke: {
            lineCap: 'round',
          },
          labels: ['购入评级', '正向', '负向'],
        },
      },


    }

  },
  props: {
    stockCode: {
      required: true,
    },
  },
}
</script>
<style scoped>

</style>