<template>
  <b-row>
    <b-col
        lg="2"
        class="d-none d-lg-block">
      <stock-link-card
          :stock-code="$route.params.stockCode">

      </stock-link-card>
    </b-col>
    <b-col
        lg="7">
      <stock-trade-card
          :stock-code="$route.params.stockCode">
      </stock-trade-card>
      <stock-news-and-discussions-card
          :stock-code="$route.params.stockCode">

      </stock-news-and-discussions-card>
    </b-col>
    <b-col
        lg="3"
        class="d-none d-lg-block">
      <stock-intro-card
          :stock-code="$route.params.stockCode">

      </stock-intro-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardHeader,
  BCardBody,
  BCardText,
  BOverlay,
  BButton,
  BLink,
} from 'bootstrap-vue'
import StockLinkCard from "@/views/Stock/StockLinkCard";
import StockTradeCard from "@/views/Stock/StockTradeCard";
import StockIntroCard from "@/views/Stock/StockIntroCard";
import StockNewsAndDiscussionsCard from "@/views/Stock/StockNewsAndDiscussionsCard";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BCardText,
    BOverlay,
    BButton,
    BLink,
    StockLinkCard,
    StockTradeCard,
    StockIntroCard,
    StockNewsAndDiscussionsCard,
  },
  data() {
    return {
      stockCode: null,
    }
  },
  beforeCreate() {

  }
}
</script>

<style scoped>

</style>