<template>
  <b-card class="position-static">
    <b-card-header>
      <b-card-title>
        <h2>{{ stockNow.name }}（{{ stockCode }}）</h2>
      </b-card-title>
      <b-button
          v-show="!mine&&loadOver"
          @click="add_mine"
          variant="outline-primary">
        <feather-icon icon="PlusIcon"></feather-icon>
        加自选
      </b-button>
      <b-button
          v-show="mine&&loadOver"
          @click="cancel_mine"
          variant="outline-primary">
        <feather-icon icon="CheckIcon"></feather-icon>
        自选
      </b-button>
    </b-card-header>


    <b-card-body>
      <b-card-title>
        <h1><strong :class="stockNow.chg_status">¥{{ stockNow.current }}</strong></h1>
        <div :class="stockNow.chg_status">{{ stockNow.chg }} {{ stockNow.percent }}</div>
      </b-card-title>
      <b-card-text>
        <span>{{ stockNow.status }}</span><span> {{ stockNow.time }} 北京时间</span>
        <table style="width: 100%">
          <tbody>
          <tr>
            <td>最高：<span>{{ stockNow.high }}</span></td>
            <td>今开：<span>{{ stockNow.open }}</span></td>
            <td>涨停：<span>{{ stockNow.up }}</span></td>
            <td>成交量：<span>{{ stockNow.volume }}</span></td>
          </tr>
          <tr>
            <td>最低：<span>{{ stockNow.low }}</span></td>
            <td>昨收：<span>{{ stockNow.close }}</span></td>
            <td>跌停：<span>{{ stockNow.down }}</span></td>
            <td>成交额：<span>{{ stockNow.turnover }}</span></td>
          </tr>
          <tr>
            <td>量比：<span>{{ stockNow.volume_ratio }}</span></td>
            <td>换手：<span>{{ stockNow.change }}</span></td>
            <td>市盈率(动)：<span>{{ stockNow.pe_dynamic }}</span></td>
            <td>市盈率(TTM)：<span>{{ stockNow.pe_ttm }}</span></td>
          </tr>
          <tr>
            <td>委比：<span>{{ stockNow.entrust_ratio }}</span></td>
            <td>振幅：<span>{{ stockNow.amplitude }}</span></td>
            <td>市盈率(静)：<span>{{ stockNow.pe_static }}</span></td>
            <td>市净率：<span>{{ stockNow.pb }}</span></td>
          </tr>
          <tr>
            <td>每股收益：<span>{{ stockNow.eps }}</span></td>
            <td>股息(TTM)：<span>{{ stockNow.dividend_ttm }}</span></td>
            <td>总股本：<span>{{ stockNow.share_capital }}</span></td>
            <td>总市值：<span>{{ stockNow.market_value }}</span></td>
          </tr>
          <tr>
            <td>每股净资产：<span>{{ stockNow.net_assets }}</span></td>
            <td>股息率(TTM)：<span>{{ stockNow.dividend_ratio }}</span></td>
            <td>流通股：<span>{{ stockNow.outstanding_shares }}</span></td>
            <td>流通值：<span>{{ stockNow.outstanding_value }}</span></td>
          </tr>
          <tr>
            <td>52周最高：<span>{{ stockNow.high52 }}</span></td>
            <td>52周最低：<span>{{ stockNow.low52 }}</span></td>
            <td>货币单位：<span>{{ stockNow.currency }}</span></td>
          </tr>
          </tbody>
        </table>
      </b-card-text>
      <b-card-text style="border-style: solid;border-width: thin">
        <div style="border-bottom-style:solid;border-width: thin;text-align: center">
          <b-dropdown
              variant="flat-primary"
              right
              size="sm"
              :text="k_period_name">
            <b-dropdown-item @click="minute_click">分时</b-dropdown-item>
            <b-dropdown-item @click="day_click">日K</b-dropdown-item>
            <b-dropdown-item @click="week_click">周K</b-dropdown-item>
            <b-dropdown-item @click="month_click">月K</b-dropdown-item>
            <b-dropdown-item @click="season_click">季K</b-dropdown-item>
            <b-dropdown-item @click="year_click">年K</b-dropdown-item>
          </b-dropdown>
          <b-dropdown
              variant="flat-primary"
              right
              size="sm"
              :text="k_type_name">
            <b-dropdown-item @click="before_click">前复权</b-dropdown-item>
            <b-dropdown-item @click="after_click">后复权</b-dropdown-item>
            <b-dropdown-item @click="normal_click">不复权</b-dropdown-item>
          </b-dropdown>
        </div>
        <div id="simple_chart" style="height: 400px"/>
      </b-card-text>
    </b-card-body>


  </b-card>
</template>

<script>
import {
  BCard,
  BCardText,
  BCardTitle,
  BLink,
  BCardBody,
  BCardSubTitle,
  BCardHeader,
  BButton,
  BButtonGroup,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import {dispose, init} from 'klinecharts';
import kline_options from "@/views/Stock/kline_options";
import Toast from "@core/components/toastification/ToastificationContent";

export default {
  components: {
    BCard,
    BCardTitle,
    BCardText,
    BLink,
    BCardBody,
    BCardSubTitle,
    BCardHeader,
    BButton,
    BButtonGroup,
    BDropdown,
    BDropdownItem,
  },
  props: {
    stockCode: {
      required: true,
    },
  },
  created() {
    this.$http.get("/api/data/get_stock_data/" + this.stockCode).then(response => {
      if (response.data.code !== 200) {
        this.showToast("danger", "抱歉", "AlertCircleIcon", response.data.data, "top-center", 2000)
        this.$router.push({name: "error-404"})
      } else {
        this.stockNow = response.data.data
        for (let i in this.stockNow) {
          if (this.stockNow[i] === '' || this.stockNow[i] === null)
            this.stockNow[i] = "无"
        }
      }
    }).catch(error => {
      this.showToast("danger", "抱歉", "AlertCircleIcon", "服务器错误：" + error, "top-center", 2000)
    })
    this.get_kline("day", this.k_type_value)

    this.$http.get("/api/system/stock/userSelect/status/" + this.stockCode).then(response => {
      if (response.data.code === 200) {
        this.loadOver = true
        this.mine = response.data.data
      }
    }).catch(error => {
      this.showToast("danger", "抱歉", "AlertCircleIcon", "服务器错误：" + error, "top-center", 2000)
    })

  },
  data() {
    return {
      mine: false,
      loadOver: false,
      stockNow: {
        time: "",
        high: "",
        open: "",
        up: "",
        volume: "",
        low: "",
        close: "",
        down: "",
        turnover: "",
        volume_ratio: "",
        change: "",
        pe_dynamic: "",
        pe_ttm: "",
        entrust_ratio: "",
        amplitude: "",
        pe_static: "",
        pb: "",
        eps: "",
        dividend_ttm: "",
        share_capital: "",
        market_value: "",
        net_assets: "",
        dividend_ratio: "",
        outstanding_shares: "",
        outstanding_value: "",
        high52: "",
        low52: "",
        currency: "",
        status: "",
        tags: "",
        name: "",
        current: "",
        percent: "",
        chg: "",
        chg_status: "",
      },
      k_data: [],
      k_chart: null,
      k_period_name: "日K",
      k_period_value: "day",
      k_type_name: "前复权",
      k_type_value: "before"
    }
  },
  methods: {
    add_mine() {
      this.$http.get("/api/system/stock/userSelect/add/" + this.stockCode).then(response => {
        if (response.data.code === 200) {
          this.mine = true
        }else{
          this.showToast("danger", "抱歉", "AlertCircleIcon", response.data.message, "top-center", 2000)
        }
      }).catch(error => {
        this.showToast("danger", "抱歉", "AlertCircleIcon", "服务器错误：" + error, "top-center", 2000)
      })
    },
    cancel_mine() {
      this.$http.get("/api/system/stock/userSelect/remove/" + this.stockCode).then(response => {
        if (response.data.code === 200) {
          this.mine = false
        }else{
          this.showToast("danger", "抱歉", "AlertCircleIcon", response.data.message, "top-center", 2000)
        }
      }).catch(error => {
        this.showToast("danger", "抱歉", "AlertCircleIcon", "服务器错误：" + error, "top-center", 2000)
      })
    },
    minute_click() {

    },
    day_click() {
      this.get_kline("day", this.k_type_value)
      this.k_period_name = "日K"
      this.k_period_value = "day"
    },
    week_click() {
      this.get_kline("week", this.k_type_value)
      this.k_period_name = "周K"
      this.k_period_value = "week"
    },
    month_click() {
      this.get_kline("month", this.k_type_value)
      this.k_period_name = "月K"
      this.k_period_value = "month"
    },
    season_click() {
      this.get_kline("quarter", this.k_type_value)
      this.k_period_name = "季K"
      this.k_period_value = "quarter"
    },
    year_click() {
      this.get_kline("year", this.k_type_value)
      this.k_period_name = "年K"
      this.k_period_value = "year"
    },
    before_click() {
      this.get_kline(this.k_period_value, "before")
      this.k_type_name = "前复权"
      this.k_type_value = "before"
    },
    after_click() {
      this.get_kline(this.k_period_value, "after")
      this.k_type_name = "后复权"
      this.k_type_value = "after"
    },
    normal_click() {
      this.get_kline(this.k_period_value, "normal")
      this.k_type_name = "不复权"
      this.k_type_value = "normal"
    },
    get_kline(period, type) {
      this.$http.get("/api/data/get_stock_kline/" + this.stockCode + "?period=" + period + "&type=" + type).then(response => {
        if (response.data.code !== 200) {
          this.showToast("danger", "抱歉", "AlertCircleIcon", response.data.data, "top-center", 2000)
          this.$router.push({name: "error-404"})
        } else {
          this.k_data = response.data.data
          this.k_chart.applyNewData(this.k_data)
        }
      }).catch(error => {
        this.showToast("danger", "抱歉", "AlertCircleIcon", "服务器错误：" + error, "top-center", 2000)
      })
    },
    showToast(variant, title, icon, text, position, timeout) {
      this.$toast({
            component: Toast,
            props: {
              title: title,
              icon: icon,
              text: text,
              variant,
            },
          },
          {
            position,
            timeout
          })
    },
  },
  mounted: function () {
    // Init chart
    this.k_chart = init('simple_chart', kline_options.options);
    // Create main technical indicator MA
    this.k_chart.createTechnicalIndicator('MA', false, {id: 'candle_pane'});
    // Create sub technical indicator VOL
    this.k_chart.createTechnicalIndicator('VOL');
    // Fill data
    this.k_chart.applyNewData(this.k_data);
  },
  destroyed: function () {
    dispose('simple_chart');
  }
}
</script>

<style scoped>
.stock-rise {
  color: #ff0000;
}

.stock-fall {
  color: #008000;
}

.stock-hold {
  color: #707070;
}
</style>