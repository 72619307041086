<template>
  <b-card>
    <b-card-title>
      公司概况
    </b-card-title>
    <b-card-text>
      <b-link target="_blank" :href="links.basic_info">
        公司简介
      </b-link>
    </b-card-text>
    <b-card-text>
      <b-link target="_blank" :href="links.executives">
        公司高管
      </b-link>
    </b-card-text>
    <b-card-text>
      <b-link target="_blank" :href="links.ipo">
        IPO文件
      </b-link>
    </b-card-text>
    <hr>

    <b-card-title>
      股权信息
    </b-card-title>
    <b-card-text>
      <b-link target="_blank" :href="links.capital_structure">
        股本结构
      </b-link>
    </b-card-text>
    <b-card-text>
      <b-link target="_blank" :href="links.shareholder">
        十大股东
      </b-link>
    </b-card-text>
    <b-card-text>
      <b-link target="_blank" :href="links.shareholders_circulation">
        十大流通股东
      </b-link>
    </b-card-text>
    <b-card-text>
      <b-link target="_blank" :href="links.fund">
        基金持股
      </b-link>
    </b-card-text>
    <b-card-text>
      <b-link target="_blank" :href="links.executives_trade">
        高管增减持
      </b-link>
    </b-card-text>
    <b-card-text>
      <b-link target="_blank" :href="links.shareholder_count">
        股东人数
      </b-link>
    </b-card-text>
    <b-card-text>
      <b-link target="_blank" :href="links.lift_ban">
        禁售解禁
      </b-link>
    </b-card-text>

    <hr>
    <b-card-title>
      股票交易
    </b-card-title>
    <b-card-text>
      <b-link target="_blank" :href="links.transaction_detail">
        成交明细
      </b-link>
    </b-card-text>
    <b-card-text>
      <b-link target="_blank" :href="links.price_detail">
        分价表
      </b-link>
    </b-card-text>
    <b-card-text>
      <b-link target="_blank" :href="links.big_order_detail">
        大单统计
      </b-link>
    </b-card-text>
    <b-card-text>
      <b-link target="_blank" :href="links.bulk_order_detail">
        大宗交易
      </b-link>
    </b-card-text>
    <b-card-text>
      <b-link target="_blank" :href="links.dtl_detail">
        龙虎榜数据
      </b-link>
    </b-card-text>
    <b-card-text>
      <b-link target="_blank" :href="links.margin_financing">
        融资融券
      </b-link>
    </b-card-text>


    <hr>
    <b-card-title>
      公司公告
    </b-card-title>
    <b-card-text>
      <b-link target="_blank" :href="links.announcement">
        最新公告
      </b-link>
    </b-card-text>
    <b-card-text>
      <b-link target="_blank" :href="links.year_report">
        年度报告
      </b-link>
    </b-card-text>

    <hr>
    <b-card-title>
      公司运作
    </b-card-title>
    <b-card-text>
      <b-link target="_blank" :href="links.shareholders_meeting">
        股东大会
      </b-link>
    </b-card-text>
    <b-card-text>
      <b-link target="_blank" :href="links.income_composition">
        收入构成
      </b-link>
    </b-card-text>
    <b-card-text>
      <b-link target="_blank" :href="links.major_issues">
        重大事项
      </b-link>
    </b-card-text>
    <b-card-text>
      <b-link target="_blank" :href="links.dividend">
        分红送配
      </b-link>
    </b-card-text>
    <b-card-text>
      <b-link target="_blank" :href="links.additional_issuance">
        增发一览
      </b-link>
    </b-card-text>
    <b-card-text>
      <b-link target="_blank" :href="links.internal_trade">
        内部交易
      </b-link>
    </b-card-text>
    <b-card-text>
      <b-link target="_blank" :href="links.ir">
        IR活动记录
      </b-link>
    </b-card-text>

    <hr>
    <b-card-title>
      财务数据
    </b-card-title>
    <b-card-text>
      <b-link target="_blank" :href="links.performance_report">
        业绩预告
      </b-link>
    </b-card-text>
    <b-card-text>
      <b-link target="_blank" :href="links.main_index">
        主要指标
      </b-link>
    </b-card-text>
    <b-card-text>
      <b-link target="_blank" :href="links.profit">
        利润表
      </b-link>
    </b-card-text>
    <b-card-text>
      <b-link target="_blank" :href="links.assets_liabilities">
        资产负债表
      </b-link>
    </b-card-text>
    <b-card-text>
      <b-link target="_blank" :href="links.cash_flow">
        现金流量表
      </b-link>
    </b-card-text>
    <b-card-text>
      <b-link target="_blank" :href="links.equity_changes">
        所有者权益变动
      </b-link>
    </b-card-text>


    <hr>
    <b-card-title>
      研究分析
    </b-card-title>
    <b-card-text>
      <b-link target="_blank" :href="links.dupont">
        杜邦分析
      </b-link>
    </b-card-text>
    <b-card-text>
      <b-link target="_blank" :href="links.research_report">
        研究报告
      </b-link>
    </b-card-text>

  </b-card>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardText,
  BLink,
} from 'bootstrap-vue'
import Toast from "@core/components/toastification/ToastificationContent";

export default {
  components: {
    BCard,
    BCardTitle,
    BCardText,
    BLink,
  },
  props: {
    stockCode: {
      required: true,
    },
  },
  data() {
    return {
      links: {
        shareholders_meeting: '',
        additional_issuance: '',
        announcement: '',
        assets_liabilities: '',
        basic_info: '',
        big_order_detail: '',
        bulk_order_detail: '',
        capital_structure: '',
        cash_flow: '',
        dividend: '',
        dtl_detail: '',
        dupont: '',
        equity_changes: '',
        executives: '',
        executives_trade: '',
        fund: '',
        income_composition: '',
        internal_trade: '',
        ipo: '',
        ir: '',
        lift_ban: '',
        main_index: '',
        major_issues: '',
        margin_financing: '',
        performance_report: '',
        price_detail: '',
        profit: '',
        research_report: '',
        shareholder: '',
        shareholder_count: '',
        shareholders_circulation: '',
        transaction_detail: '',
        year_report: '',
      },
    }
  },
  created() {
    this.$http.get("/api/data/get_stock_links/" + this.stockCode).then(response => {
      if (response.data.code !== 200) {
        this.showToast("danger", "抱歉", "AlertCircleIcon", response.data.data, "top-center", 2000)
        this.$router.push({name: "error-404"})
      } else
        this.links = response.data.data
    }).catch(error => {
      this.showToast("danger", "抱歉", "AlertCircleIcon", "服务器错误：" + error, "top-center", 2000)
    })
  },
  methods: {
    showToast(variant, title, icon, text, position, timeout) {
      this.$toast({
            component: Toast,
            props: {
              title: title,
              icon: icon,
              text: text,
              variant,
            },
          },
          {
            position,
            timeout
          })
    }
  }
}
</script>

<style scoped>

</style>